<template>
  <drawer
    :visible="visible"
    title="Event Details"
    class="unsent-email-drawer"
    size="35%"
    @update:visible="$emit('update:visible', $event)"
  >
    <el-row>
      <label>Event Type:</label>
      <div class="event-detail capitalize">
        {{ report.event && report.event.type }}
        <el-tooltip
          v-if="tooltip"
          popper-class="unsent-email-tooltip"
        >
          <template #content>
            <b>Troubleshooting:</b>
            {{ tooltip }}
          </template>
          <el-button icon="el-icon-info" type="text" />
        </el-tooltip>
      </div>
    </el-row>
    <el-row>
      <label>Date and Time:</label>
      <div class="event-detail">
        {{ report.timestamp ? format(parseISO(report.timestamp), 'yyyy-MM-dd h:mm a') : '' }}
      </div>
    </el-row>
    <el-row>
      <label>Email Account:</label>
      <div class="event-detail">
        <link-button
          v-if="report.account"
          :to="{ name: 'RooofAccountEdit', params: { id: report.account }}"
          type="primary"
        >
          {{ report.address }}
        </link-button>
        <template v-else>
          {{ report.address }}
        </template>
      </div>
    </el-row>
    <el-row>
      <label>Event:</label>
      <!-- content needs to be on the same line to avoid whitespace being added to pre -->
      <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
      <div class="event-detail code">{{ report.event && report.event.swu }}
      </div>
    </el-row>
    <el-row>
      <label>Removals:</label>
      <!-- eslint-disable-next-line vue/multiline-html-element-content-newline -->
      <div class="event-detail code">{{ report.removals }}
      </div>
    </el-row>
  </drawer>
</template>

<script>
import Drawer from '@/components/drawers/Drawer'
import LinkButton from '@/components/buttons/LinkButton'
import { format, parseISO } from 'date-fns'

export default {
  name: 'UnsentEmailReportDrawer',
  components: {
    'drawer': Drawer,
    'link-button': LinkButton
  },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    report: {
      type: Object,
      required: true
    }
  },
  computed: {
    tooltip () {
      if (!this.report.event) {
        return null
      }
      switch (this.report.event.type) {
        case 'bounced':
          return `This address is invalid. Check for typos/confirm with the user.
                  To resolve, update the address and add it back to the reporting list(s) it was
                  removed from in Admin.`
        case 'dropped':
          return `This address has previously bounced or the user has unsubscribed.
                  
                  If the address has previously bounced, check for typos/confirm with the user.
                  To resolve, update the address and add it back to the reporting list(s) it was
                  removed from in Admin.
                  
                  If the user has unsubscribed, remove the address from the Global Unsubscribes
                  list in SendGrid and add it back to the reporting list(s) it was removed from
                  in Admin.`
        case 'complained':
          return `The user has marked our email as spam. To resolve, contact the user and ask
                  them to whitelist our domain. Then remove the address from the Global Unsubscribes
                  list in SendGrid and add it back to the reporting list(s) it was removed from in Admin.`
        default:
          return null
      }
    }
  },
  created () {
    this.format = format
    this.parseISO = parseISO
  }
}
</script>

<style lang="scss" scoped>
.unsent-email-drawer {
  ::v-deep .el-drawer__header > span {
    font-size: 1.25rem;
    font-weight: bold;
  }
  label {
    font-weight: bold;
  }
  .event-detail {
    margin: 0.25em 0 2em;
    word-wrap: break-word;

    &.code {
      background-color: #dcdfe6; // $--border-color-base
      white-space: pre;
      font-family: monospace;
      max-height: 200px;
      overflow: auto;
      padding: 0.5em;
    }
  }
  .capitalize {
    text-transform: capitalize;
  }
}
</style>

<style>
.unsent-email-tooltip {
  max-width: 500px;
  white-space: pre-line;
}
</style>
