import { paginatedRequest } from './index'

const EmailNotificationsAPI = {
  bounced_emails: {
    list: async (params = {}) => {
      const url = '/email-notifications/bounced-emails/'
      const pageSize = 100
      const response = await paginatedRequest(url, pageSize, params)
      return response.data
    }
  }
}

export default EmailNotificationsAPI
