<template>
  <data-table :data="reports" height="calc(100vh - 310px)">
    <el-table-column
      label="Date and Time"
      prop="timestamp"
      sortable
    >
      <template slot-scope="scope">
        {{ format(parseISO(scope.row.timestamp), 'yyyy-MM-dd h:mm a') }}
      </template>
    </el-table-column>
    <el-table-column
      label="Email Account"
      prop="address"
      sortable
    />
    <el-table-column
      label="Event Type"
      prop="event.type"
      class-name="capitalize"
      sortable
    />
    <el-table-column width="120" align="center">
      <template slot-scope="scope">
        <el-button
          type="text"
          @click="$emit('showDetails', scope.row)"
        >
          More Details
        </el-button>
      </template>
    </el-table-column>
  </data-table>
</template>

<script>
import DataTable from '@/components/tables/DataTable'
import { format, parseISO } from 'date-fns'
export default {
  name: 'UnsentEmailReportsTable',
  components: {
    'data-table': DataTable
  },
  props: {
    reports: {
      type: Array,
      required: true
    }
  },
  created () {
    this.format = format
    this.parseISO = parseISO
  }
}
</script>

<style lang="scss" scoped>
::v-deep .capitalize {
  text-transform: capitalize;
}
</style>
