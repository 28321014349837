<template>
  <div v-loading="loading" class="unsent-email-reports">
    <el-row
      class="content"
      type="flex"
      justify="space-between"
      align="bottom"
    >
      <el-input
        v-model="filter"
        placeholder="Filter by Email Account"
        size="medium"
        clearable
      />
      <div>
        <span class="date-picker-label">Showing results from</span>
        <date-range-picker :dates="dateRange" @date-change="handleDateChange" />
      </div>
    </el-row>
    <unsent-email-reports-table
      :reports="filteredReports"
      @showDetails="openDetails"
    />
    <unsent-email-report-drawer
      :visible.sync="showDetailsDrawer"
      :report="selectedReport"
    />
  </div>
</template>

<script>
import EmailNotificationsAPI from '@/services/api/emailNotifications'
import DateRangePicker from '@/components/dates/DateRangePicker'
import UnsentEmailReportsTable from './_components/UnsentEmailReportsTable'
import UnsentEmailReportDrawer from './_components/UnsentEmailReportDrawer'

export default {
  name: 'UnsentEmailReports',
  components: {
    'unsent-email-reports-table': UnsentEmailReportsTable,
    'unsent-email-report-drawer': UnsentEmailReportDrawer,
    'date-range-picker': DateRangePicker
  },
  data () {
    return {
      loading: false,
      reports: [],
      dateRange: [
        this.$moment().startOf('day').toDate(),
        this.$moment().startOf('day').toDate()
      ],
      filter: '',
      showDetailsDrawer: false,
      selectedReport: {}
    }
  },
  computed: {
    filteredReports () {
      const query = this.filter.toLowerCase()
      return this.reports.filter(report => report.address.toLowerCase().includes(query))
    }
  },
  created () {
    this.fetchUnsentEmails()
  },
  methods: {
    /**
     * onChange handler for datepicker component.
     *
     * @param {(Array|null)} dates - array of date objects, or null if the date range was cleared
     */
    handleDateChange (dates) {
      this.dateRange = dates || []

      if (this.dateRange.length) {
        this.fetchUnsentEmails()
      }
    },
    /**
     * Fetch unsent emails from API within the date range
     */
    async fetchUnsentEmails () {
      try {
        this.loading = true
        const params = new URLSearchParams()
        if (this.dateRange[0]) {
          params.append('timestamp_after', this.dateRange[0].toISOString())
        }
        if (this.dateRange[1]) {
          // add a day so end date is inclusive
          const end = this.$moment(this.dateRange[1]).add(1, 'day').toDate().toISOString()
          params.append('timestamp_before', end)
        }
        const response = await EmailNotificationsAPI.bounced_emails.list(params)
        this.reports = response
      } catch (err) {
        const details = err.response ? err.response.data : null
        this.$rfAlert.error(this, err, details)
      } finally {
        this.loading = false
      }
    },
    /**
     * Opens the event details drawer.
     */
    openDetails (report) {
      this.selectedReport = report
      this.showDetailsDrawer = true
    }
  }
}
</script>

<style lang="scss" scoped>
.unsent-email-reports {
  .header {
    margin: 0;
    font-size: 2rem;
  }
  .date-picker-label {
    margin-right: 0.5em;
  }
  .content {
    margin-top: 1em;

    .el-input {
      width: auto;
    }
  }
}
</style>
